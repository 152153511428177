<template>
  <v-app>
    <div id="app">
      <div class="absolute">
      <router-view></router-view>
      </div>
      <div class="absolute">
        <img alt="window frame" class="window-frame" src="./assets/snow.webp">
      </div>
      <div class="absolute">
        <img alt="window frame" class="window-frame" src="./assets/window.png">
        <div class="abs-bottom">
          <v-btn class="left" color="green" icon v-on:click="startMusic(0)" >
            <v-icon x-large>
              mdi-pine-tree
            </v-icon>
          </v-btn>
          <v-btn v-on:click="stopMusic()" v-if="isMusicPlaying">Stop</v-btn>
          <v-btn v-on:click="startMusic()" v-else>Musik</v-btn>
          <v-btn class="right" color="yellow" icon v-on:click="startMusic(1)" >
            <v-icon x-large>
              mdi-bell
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="absolute-text">
        <div class="font">Frohe<br>Weihnachten</div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({})
class App extends Vue {
  isPlaying = false;
  delay = 0.3;
  audio = null;
  
  get isMusicPlaying() {
    return this.audio !== null && this.isPlaying;
  }

  startMusic(type=-1) {
    if (this.isMusicPlaying) {
      this.stopMusic();
    }
    if (this.audio === null) {
      this.audio = new Audio();
    }
    if (type === -1) {
      type = Math.round(Math.random());
    }
    switch(type){
      case 0:
        this.audio.src = 'tannenbaum.mp3';
        break;
      case 1:
        this.audio.src = 'jinglebells.mp3';
        break;
    }
    this.isPlaying = true;
    this.audio.addEventListener('ended', () => {
      this.isPlaying = false;
    });
    this.audio.play();
  }

  /*playJingleBells(time) {
    // Don't judge me, judge the compiler...
    let next0 = this.playJingleBellsStrophe(time+1);
    let next1 = this.playJingleBellsRefrain(next0);
    next0 = this.playJingleBellsStrophe(next1);
    next1 = this.playJingleBellsRefrain(next0);
    next0 = this.playJingleBellsStrophe(next1);
    next1 = this.playJingleBellsRefrain(next0);
    return next1;
  }

  playOhTannenbaum(time) {
    let next0 = this.playOhTannenbaumStrophe(time+1);
    let next1 = this.playOhTannenbaumStrophe(next0);
    next0 = this.playOhTannenbaumStrophe(next1);
    return next0;
  }

  playOhTannenbaumStrophe(time) {
    let now = time;
    //oh tannenbaum
    this.synth.triggerAttackRelease("C4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "12n", now);
    now += this.delay+this.delay/2;
    this.synth.triggerAttackRelease("F4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("F4", "6n", now);
    now += this.delay*2+this.delay;

    //oh tannenbaum
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "12n", now);
    now += this.delay+this.delay/2;
    this.synth.triggerAttackRelease("A4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("A4", "6n", now);
    now += this.delay*2+this.delay;

    // wie gruen...
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "4n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("E4", "4n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("G4", "4n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("F4", "4n", now);
    now += this.delay*2+this.delay;

    // du gruenst nicht nu..
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("D5", "6n", now);
    now += this.delay*2+this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "6n", now);
    now += this.delay*2+this.delay;
    
    // nein auch im Winter..
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "6n", now);
    now += this.delay*2+this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "4n", now);
    now += this.delay*2+this.delay;

    this.synth.triggerAttackRelease("C4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "12n", now);
    now += this.delay+this.delay/2;
    this.synth.triggerAttackRelease("F4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("F4", "6n", now);
    now += this.delay*2+this.delay;

    //oh tannenbaum
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "12n", now);
    now += this.delay+this.delay/2;
    this.synth.triggerAttackRelease("A4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("A4", "6n", now);
    now += this.delay*2+this.delay;

    // wie gruen...
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "4n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("E4", "4n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("G4", "4n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("F4", "4n", now);
    now += this.delay*2+this.delay;
    return now;
  }

  playJingleBellsStrophe(time) {
    let now = time;
    this.synth.triggerAttackRelease("C4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C4", "6n", now);
    now += this.delay*2+this.delay;
    this.synth.triggerAttackRelease("C4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("C4", "16n", now);
    now += this.delay/2;

    this.synth.triggerAttackRelease("C4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("D4", "6n", now);
    now += this.delay*2+this.delay;
    now += this.delay;

    this.synth.triggerAttackRelease("D4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("E4", "4n", now);
    now += this.delay*4;

    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "6n", now);
    now += this.delay*2+this.delay;
    now += this.delay;

    this.synth.triggerAttackRelease("C4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C4", "4n", now);
    now += this.delay*4;

    this.synth.triggerAttackRelease("C4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("D4", "6n", now);
    now += this.delay*2+this.delay;
    this.synth.triggerAttackRelease("D4", "8n", now);
    now += this.delay;

    this.synth.triggerAttackRelease("D4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;

    this.synth.triggerAttackRelease("D5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "6n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("C5", "4n", now);
    now += this.delay*2;

    return now;
  }

  playJingleBellsRefrain(time) {
    let now = time;
    // jingle bells, jingle bells
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "4n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "4n", now);
    now += this.delay*2;

    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "12n", now);
    now += this.delay+this.delay/2;
    this.synth.triggerAttackRelease("G4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("A4", "4n", now);
    now += this.delay*4;

    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "12n", now);
    now += this.delay+this.delay/2;
    this.synth.triggerAttackRelease("Bb4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("A4", "16n", now);
    now += this.delay/2;

    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("C5", "4n", now);
    now += this.delay*2;

    // jingle bells, jingle bells
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "4n", now);
    now += this.delay*2;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "4n", now);
    now += this.delay*2;

    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "12n", now);
    now += this.delay+this.delay/2;
    this.synth.triggerAttackRelease("G4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("A4", "4n", now);
    now += this.delay*4;

    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "12n", now);
    now += this.delay+this.delay/2;
    this.synth.triggerAttackRelease("Bb4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("A4", "16n", now);
    now += this.delay/2;
    this.synth.triggerAttackRelease("A4", "16n", now);
    now += this.delay/2;

    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("C5", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("Bb4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("G4", "8n", now);
    now += this.delay;
    this.synth.triggerAttackRelease("F4", "4n", now);
    now += this.delay*4;

    return now;
  }

  playSound(note, duration, timing) {
    this.synth.triggerAttackRelease(note, duration, timing);
  }*/

  stopMusic() {
    if (this.audio === null) {
      return;
    }
    this.audio.pause();
    this.isPlaying = false;
  }
}
export default App;
</script>

<style lang="scss">
@font-face {
  font-family: PWHappyChristmas;
  src: url('assets/PWHappyChristmas.ttf');
}
@font-face {
  font-family: Handwriting;
  src: url('assets/IndieFlower-Regular.ttf');
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: whitesmoke;
  background-color:#440000;
  height: 300%;
  text-align: center;
}

.font {
  position: relative;
  bottom: 0px;
  font-family: 'PWHappyChristmas';
  font-size: 8vmin;
  z-index: 4;
}

.handwritten-font {
  font-family: 'Handwriting';
  font-size:  20px;
}

.background {
  z-index: 0;
  width: auto;
  max-width: 100vw;
  max-height: 60%;
}

.absolute {
  position: absolute;
  text-align: center;
  top: 0px;
  right: 0px;
  left: 0px;
  width: 100%;
  margin-top: 14vmin;

  &-text {
    margin: 0px;
  }
}

.abs-bottom {
  position: absolute;
  text-align: center;
  bottom: 13%;
  right: 0px;
  left: 0px;
  width: 100%;
}

.left {
  right: 8%;
}

.right {
  left: 8%;
}

.space {
  z-index: 2;
}

.window-frame {
  z-index: 1;
  width: auto;
  max-width: 100vw;
  max-height: 60%;
}

</style>
