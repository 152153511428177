import Vue from 'vue'
import VueRouter from 'vue-router'
import Florence from './../views/Florence'
import General from './../views/General'
import Mama from './../views/Mama'
import Meline from './../views/Meline'
import Oma from './../views/Oma'
import Papa from './../views/Papa'
import Rainer from './../views/Rainer'
import Hidden from './../views/Hidden'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'General',
    component: General
  },
  {
    path: '/8cd9b998020b69f36c009730905e03e5',
    name: 'Florence',
    component: Florence
  },
  {
    path: '/50683e59b0c08b61a09f423a402a49e5',
    name: 'Mama',
    component: Mama
  },
  {
    path: '/ad601a1888713fb8b6e85b57a46048c4',
    name: 'Meline',
    component: Meline
  },
  {
    path: '/8be7567c84f51ee0c1be0b42c87a06ae',
    name: 'Oma',
    component: Oma
  },
  {
    path: '/0bc85c6d03d5a9628af3df43a4640091',
    name: 'Papa',
    component: Papa
  },
  {
    path: '/af68dc2d769fbd0d457a66a84282ea5a',
    name: 'Rainer',
    component: Rainer
  },
  {
    path: '/04e1073dd2dbe73180eff65372371e6a',
    name: 'Hidden',
    component: Hidden
  }
]

const router = new VueRouter({
  routes
})

export default router
